import React from "react";
import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import RightSidebar from "../components/RightSidebar.js";

import TheTumbler from "../components/TheTumbler";

const allBattles = require("./battles.json");

const tacoBattlesPage = () => {
  return (
    <>
      <Head
        title="Our Taco Battles"
        description="The ultimate battle for the best tacos in Austin Texas"
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Our Taco Battles</h1>

              <section className="promo-top-taco-battles">
                Welcome to Taco Battles! This is a fun light-hearted review
                where we match up two tacos in the spirit of competition for the
                "best of" categories. It probably goes without saying, but there
                are no losers in this category... only our personal choices. We
                would love to{" "}
                <a href="mailto:austincitytacos@gmail.com">hear from you</a> on
                how these rank up for you.
              </section>

              <div className="promo-recommendation-wrapper">
                {allBattles.map((node, i) => {
                  return (
                    <section className="promo-tacobattles" key={i}>
                      <div className="contents">
                        <div className="title">
                          <a href={node.link}>{node.title}</a>
                        </div>
                        <div className="descr">{node.desc}</div>
                      </div>
                      <div className="battle-against">
                        <div className="images-against">
                          <div className="img">
                            <div className="name">{node.tacoA.nameOfTaco}</div>
                            <a href={node.link}>
                              <img src={node.tacoA.img} />
                            </a>
                          </div>
                          <div className="taco-middles">
                            <div>
                              <img src="/icons/sword-white.png" />
                            </div>
                          </div>
                          <div className="img">
                            <div className="name">{node.tacoB.nameOfTaco}</div>
                            <a href={node.link}>
                              <img src={node.tacoB.img} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </section>
                  );
                })}
              </div>
            </div>

            <div className="right-sidebar">
              <TheTumbler></TheTumbler>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default tacoBattlesPage;
